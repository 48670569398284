import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import {getApp, initializeApp, provideFirebaseApp} from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { initializeAppCheck, ReCaptchaEnterpriseProvider, provideAppCheck } from '@angular/fire/app-check';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getFunctions, provideFunctions } from '@angular/fire/functions';
import {environment} from "../environments/environment";

export const appConfig: ApplicationConfig = {
  providers: [provideZoneChangeDetection({ eventCoalescing: true }), provideRouter(routes),
    provideFirebaseApp(() => initializeApp(environment.firebaseApp)),
    provideFunctions(() => getFunctions(getApp(), environment.origin)),
    provideAuth(() => getAuth()),
    provideAppCheck(() => {
     const provider = new ReCaptchaEnterpriseProvider(environment.recaptchaKey ?? '' );
      return initializeAppCheck(getApp(), { provider, isTokenAutoRefreshEnabled: true });
    }),
    provideFirestore(() => getFirestore()), provideFunctions(() => getFunctions())]
};
