import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import {Preferences} from "@cvlized/common";
import {FlexUploadDocComponent} from "@cvlized/ng-material-ui/library";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, FlexUploadDocComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'CVlized';
  preferences?: Preferences;
}
