import {Environment} from "./environment.type";

export const environment: Environment = {
  production: false,
  firebaseApp: {
    "projectId": "cvlized",
    "appId": "1:379840777525:web:c6cfaf629b5574d577107c",
    "storageBucket": "cvlized.appspot.com",
    "apiKey": "AIzaSyDUlX85RzkjIX9tpYHlUXZrHDl25q_j7UM",
    "authDomain": "cvlized.firebaseapp.com",
    "messagingSenderId": "379840777525",
    "measurementId": "G-B3C2MTCD71"
  }
};

