import { Component } from '@angular/core';

@Component({
  selector: 'ui-flex-upload-doc',
  standalone: true,
  imports: [],
  templateUrl: './flex-upload-doc.component.html',
  styleUrl: './flex-upload-doc.component.scss'
})
export class FlexUploadDocComponent {
}
